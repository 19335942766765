<template>
  <div class="container">
    <h1>Details de missions</h1>
    <div class="in__container">
      <div class="form">
        <div class="form-body">
          <div class="row">
            <div class="cardavalider box">
              <div class="card_body">
                <div class="card_content">
                  <h2 class="card_title">{{ mission.nom_mission }}</h2>
                  <div class="card_description">
                    <p class="desc">{{ mission.description_mission }}</p>
                    <p class="comp">Competences: {{ mission.competences_requises }}</p>
                  </div>
                  <div class="card_summary">
                    <a><i class="fa-regular fa-clock"></i> Publié le : {{ formatMissionDate(mission.created_at) }} </a>
                    <a><i class="fa-solid fa-location-dot"></i> {{ mission.ville }}</a>
                    <a><i class="fa-solid fa-hourglass-start"></i> Démarage : {{ formatMissionDate(mission.date_debut_ms)
                    }}</a>
                    <a><i class="fa-solid fa-hourglass-end"></i> Fin : {{ formatMissionDate(mission.date_fin) }}</a>
                    <a><i class="fa-solid fa-hand-holding-dollar"></i> Tarif : {{ mission.tarif_mission }} €</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button class="cloturer-button" @click="cloturerMission">Clôturer Mission</button>
      </div>
      <div class="sidebar-wrapper">
        <div class="sidebar box 1">
          <h2 class="page_title">Envoyer un email au candidat </h2>
          <p v-if="errorMsg" class="error">{{ errorMsg }}</p>
          <div class="scrollable-card">
            <div v-for="candidat in candidates" :key="candidat._id" class="cv_card">
              <div v-if="loadingCandidateId === candidat._id" class="loader"></div>
              <div class="link">
                <button @click="showCandidatePopup(candidat)" class="cv">{{ candidat.nom_candidat }} {{ candidat.prenom_candidat }}</button>
              </div>
              <div class="actions card_actions">
                <a class="accept" @click="acceptCandidat(candidat, mission)" style="cursor:pointer;"><i
                    class="fa-regular fa-circle-check"></i></a>
                <a class="notaccept" @click="refuserCandidat(candidat)" style="cursor:pointer;"><i class="fa-regular fa-circle-xmark"></i></a>
              </div>
            </div>
          </div>
          <p v-if="!candidates.length">Pas de candidat pour cette mission.</p>
          <button class="cloturer-button" @click="refuseAllCandidates">Tout Refuser</button>
        </div>

        <div class="title" style="margin-top:20px;">

        </div>
        <div class="sidebar box 2">
          <h2 class="page_title">Candidats choisis</h2>
          <p v-if="errorMsg" class="error">{{ errorMsg }}</p>
          <div v-for="consultant in consultants" :key="consultant._id" class="cv_card">
            <div class="link">
              <a :href="getCvDownloadUrl(consultant.cv_candidat)" target="_blank" class="cv">{{ consultant.nom_candidat }} {{ consultant.prenom_candidat }}</a>
            </div>
            <div class="actions card_actions">
              <a class="accept" @click="makeConsultant(consultant, mission)" style="cursor:pointer;"><i
                  class="fa-regular fa-circle-check"></i></a>
              <a class="notaccept" @click="refuserCandidat(consultant)" style="cursor:pointer;"><i class="fa-regular fa-circle-xmark"></i></a>
            </div>
          </div>
          <p v-if="!consultants.length">Pas de condidat.</p>
        </div>
      </div>
    </div>
    <Popup v-if="showPopup" :candidate="selectedCandidate" :get-cv-download-url="getCvDownloadUrl"
      :get-file-name="getFileName" @close="closeCandidatePopup" />
  </div>
</template>

<script>
import Popup from './Popup.vue';
import axios from 'axios';
import Swal from 'sweetalert2';
import moment from 'moment-timezone';

export default {
  name: 'MissionDetails',
  components: {
    Popup,
  },
  data() {
    return {
      mission: {},
      candidates: [],
      consultants: [],
      errorMsg: null,
      acceptedConsultant: null,
      loadingCandidateId: null,
      showPopup: false,
      selectedCandidate: null,
    };
  },
  methods: {
    showCandidatePopup(candidate) {
      this.selectedCandidate = candidate;
      this.showPopup = true;
    },

    closeCandidatePopup() {
      this.selectedCandidate = null;
      this.showPopup = false;
    },
    formatMissionDate(date) {
      return moment(date).format('DD-MM-YYYY');
    },
    async makeConsultant(consultant) {
      try {
        const candidatId = consultant._id;
        const missionId = this.mission._id;
        const swalResult = await Swal.fire({
          title: 'Confirmation',
          text: 'Voulez-vous rendre ce candidat Consultant?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
        });

        if (swalResult.isConfirmed) {
          this.$router.push({
            name: 'registrerconsultant',
            params: { id: candidatId },
            query: { missionId: missionId }
          });
        }

      } catch (error) {
        console.error('Error in makeConsultant:', error);
        Swal.fire('Erreur', 'Une erreur s\'est produite.', 'error');
      }
    },


    async cloturerMission() {
      try {
        const missionId = this.mission._id;
        const response = await axios.post(`https://missions-freelance-industrie.fr/missions/${missionId}/cloturer`);

        if (response.data && response.data.message) {
          Swal.fire('Succès', 'Mission clôturée avec succes', 'success');
          this.$router.push('/MissionsValidees');
        } else {
          Swal.fire('Erreur', 'Something went wrong.', 'error');
        }
      } catch (error) {
        console.error('Error while closing mission:', error);
        if (error.response && error.response.data && error.response.data.message) {
          Swal.fire('Erreur', error.response.data.message, 'error');
        } else {
          Swal.fire('Erreur', 'Failed to close the mission.', 'error');
        }
      }
    },
    getFileName(path) {
      let fileName = path.split('/').pop();
      fileName = fileName.split('.')[0] + '.pdf';
      return fileName;
    },
    getCvDownloadUrl(relativePath) {
      return `https://missions-freelance-industrie.fr/${relativePath.replace('public/', '')}`;
    },
    async acceptCandidat(candidat) {
      this.loadingCandidateId = candidat._id; // Show the loader for this candidate

      try {
        this.currentCandidate = candidat;
        await this.updateConsultantStatus(candidat._id);
        await this.confirmAcceptCandidate();
        await this.fetchCandidats();
      } catch (error) {
        console.error('Error in acceptCandidat:', error);
        Swal.fire('Error', 'Failed to accept the candidate.', 'error');
      } finally {
        this.loadingCandidateId = null; // Hide the loader
      }
    },
    async updateConsultantStatus(candidatId) {
      const response = await axios.put(`https://missions-freelance-industrie.fr/candidature/${candidatId}`, {
        consultant_status: true
      });

      if (response.status !== 200) {
        throw new Error('Failed to update consultant status');
      }
    },
    async confirmAcceptCandidate() {
      try {
        const missionId = this.mission._id;
        const candidatId = this.currentCandidate._id;
        const url = `https://missions-freelance-industrie.fr/missions/${missionId}/candidats/${candidatId}/accept`;
        const response = await axios.post(url);

        // Swal.fire('Success', 'un email a été envoyer !', 'success');

        // Update the arrays
        this.candidates = this.candidates.filter(candidate => candidate._id !== this.currentCandidate._id);
        this.consultants.push(this.currentCandidate);

        this.acceptedConsultant = this.currentCandidate;

      } catch (error) {
        console.error('Error while accepting candidat: ', error);
        if (error.response) {
          console.error('Error response data: ', error.response.data);
        }
      }
    },
    async refuseAllCandidates() {
      try {
        for (let candidate of this.candidates) {
          await this.refuserCandidat(candidate);
        }
        this.candidates = [];
        Swal.fire('Success', 'Tous les candidats ont été refusés.', 'success');
      } catch (error) {
        console.error('Erreur lors du refus de tous les candidats: ', error);
        Swal.fire('Error', 'Échec du refus de tous les candidats.', 'error');
      }
    },
    async refuserCandidat(candidat) {
      try {
        const data = {
          missionId: this.mission._id,
          candidatId: candidat._id
        };
        const url = 'https://missions-freelance-industrie.fr/missions/candidats/refuser';
        const response = await axios.post(url, data);
        alert('Candidate refuser avec succees!');
        this.fetchCandidats();
      } catch (error) {
        this.$router.push('/MissionsValidees')
      }
    },
    fetchMission() {
      axios.get(`https://missions-freelance-industrie.fr/missions/${this.$route.params.id}`)
        .then(response => {
          this.mission = response.data.data;
        })
        .catch(error => {
          console.error(error);
          this.errorMsg = "Failed to fetch mission details.";
        });
    },
    fetchCandidats() {
      axios.get(`https://missions-freelance-industrie.fr/missions/${this.$route.params.id}/candidats`)
        .then(response => {
          if (response.data.candidats && response.data.candidats.length) {
            this.candidates = response.data.candidats.filter(candidat => !candidat.consultant_status);
            this.consultants = response.data.candidats.filter(candidat => candidat.consultant_status);
          } else {
            this.errorMsg = "No candidates or consultants found for this mission.";
          }
        })
        .catch(error => {
          console.error(error);
          this.errorMsg = "Failed to fetch candidates or consultants.";
        });
    }
  },
  created() {
    this.fetchMission();
    this.fetchCandidats();
  }
};
</script>

  
<style scoped>
* {
  font-family: Helvetica, sans-serif;
}

.container {
  margin-top: 30px !important;
  margin: 0 auto;
}

.in__container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  margin-top: 30px !important;
  justify-content: space-between;
}

.container .title {
  width: 90%;
  margin: 0 auto;
}

.in__container .form {
  width: 60%;
}

.page_title,
.card_title {
  font-weight: bold;
}

.sidebar {
  flex-direction: column;
  align-items: center;
}

.sidebar h2 {
  margin-bottom: 20px;
}

.in__container .sidebar {
  width: 100%;
  margin: 15px;
  padding: 30px 0px;
  border: 1px solid #e9e0e0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}

/* .in__container .sidebar a {
   padding: 15px 10px;
    background: #146C94;
    color: #fff;
    border-radius: 5px;
    font-size: 18px;
    text-decoration: none;
} */

.in__container .sidebar a:hover {
  /* background: #19A7CE; */
  transition: 0.3s;
}

.cardavalider {
  padding: 30px 20px;
  border: 1px solid #e9e0e0;
  border-radius: 10px;
  margin: 15px 0;
}

.card_body {
  display: flex;
  align-items: center;
}

.desc h3 {
  font-weight: bold;
  margin: 15px 0;
}

.card_description {
  margin-bottom: 20px;
}

.card_description .desc,
.comp {
  line-height: 2em;
}

.card_summary {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  column-gap: normal;
}

.card_summary a {
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 0.5em;
  text-align: center;
}

.card_summary svg {
  font-size: 20px;
}

.card_content {
  width: 95%;
}

.card_actions {
  width: 5%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  height: 100px;
}

.card_actions svg {
  font-size: 30px;
}

.card_actions .accept {
  color: #29aa65;
}

.card_actions .notaccept {
  color: #c13636;
}

.form-body h1 {
  text-align: center;
}

/* .row {
  display: flex;
  flex-direction: row;
  justify-content: center;
} */

.cv_card {
  display: flex;
  align-items: center;
  width: 90%;
  padding: 0px 25px;
  margin: 10px 0px;
  border: 1px solid #e9e0e0;
  border-radius: 10px;
  background: #e8e8e8;
}

.card_actions i {
  font-size: 26px;
}

.cv_card .cv {
  text-decoration: none;
    font-weight: bold;
    color: #000;
    border: none;
    background: none;
}
.cv_card .cv:hover {
    color: #2178ba;
    transition: 0.5s;
}

.link {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: auto;
}

.link a {
  margin: 10px 0;
}

.actions {
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.box {
  background: #fff;
  box-shadow: 0px 3px 5px -2px rgba(0, 0, 0, 0.5);
}

.error {
  color: red;
  font-weight: bold;
  margin: 10px 0;
}

.cloturer-button {
  background-color: #d40808;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  margin: 20px auto 0 auto;
  font-weight: bold;
  transition: background-color 0.3s;
}

.cloturer-button:hover {
  background-color: #aa0303;
}

.sidebar-wrapper {
  display: flex;
  flex-direction: column;
  align-items: right;
  width: 70%;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.scrollable-card {
  max-height: 150px;
  width: 80%;
  overflow-y: auto;
}

/* Add your media queries here */
@media only screen and (max-width: 900px) {
  .cv_card {
    width: 90%;
  }

  .link {
    width: 80%;
  }

  .actions {
    width: 20%;
  }
}

@media only screen and (max-width: 700px) {
  .row {
    margin: 0;
    flex-direction: column;
  }

  .in__container {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .title {
        margin-top: 15px;
    }

  .in__container .form {
    width: 100%;
  }
  .sidebar-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 40px;
}

  .in__container .sidebar {
    width: 100%;
    margin: 0;
  }

  .card_body {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .card_actions {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .card_actions svg {
    font-size: 35px;
  }

  .card_body .card_summary {
    display: flex;
    row-gap: 0.5em;
    align-items: flex-start;
    flex-direction: column;
  }

  .card_summary a {
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    row-gap: 0.5em;
    text-align: center;
    column-gap: 0.5em;
  }
}


</style>