<template>
    <div class="container">
        <div class="title">
            <h1 class="page_title">Liste des missions à valider</h1>
        </div>
        <div class="in__container">
            <div v-for="card in cards" :key="card._id" class="cardavalider box">
                <div class="card_body">
                    <div class="card_content">
                        <h2 class="card_title">{{ card.nom_mission }}</h2>
                        <div class="card_description" style="margin-bottom:20px;">
                            <p class="desc">{{ card.description_mission }}</p>
                            <p class="comp">Competences: {{ card.competences_requises }}</p>
                        </div>
                        <div class="card_summary">
                            <a><i class="fa-regular fa-clock"></i> Publié le : {{ formatMissionDate(card.created_at) }}</a>
                            <a><i class="fa-solid fa-location-dot"></i> {{ card.ville }}</a>
                            <a><i class="fa-solid fa-hourglass-start"></i> Date debut : {{
                                formatMissionDate(card.date_debut_ms) }}</a>
                            <a><i class="fa-regular fa-calendar-days"></i> Date fin : {{ formatMissionDate(card.date_fin)
                            }}</a>
                        </div>
                    </div>
                    <div class="card_actions">
                        <div class="accept" style="cursor:pointer;">
                            <a @click="accept(card)"><i class="fa-regular fa-circle-check"></i></a>
                        </div>
                        <div class="notaccept" style="cursor:pointer;">
                            <a @click="notAccept(card)"><i class="fa-regular fa-circle-xmark"></i></a>
                        </div>
                        <div class="details">
                            <router-link :to="`/missiondetailsavalider/${card._id}`">
                                <i class="fa-regular fa-eye"></i>
                            </router-link>
                        </div>
                        <div class="details" style="cursor:pointer;">
                            <a @click="modifier(card._id)" class=""><i class="fa-solid fa-file-pen"></i></a>
                        </div>
                    </div>
                </div>
                <!--
                <hr>
                <div class="btn-container">
                    <router-link :to="`/modifiermission/${card._id}`">
                                <i class="fa-regular fa-eye"></i>
                    </router-link>
                    
                </div>
                -->
            </div>
        </div>
    </div>
</template>

  
<script>
import axios from 'axios';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';


export default {
    data() {
        return {
            cards: [],
        };
    },
    methods: {
        modifier(id){
            this.$router.push(`/modifiermission/${id}`);
        },
        formatMissionDate(date) {
            return moment(date).format('DD-MM-YYYY');
        },
        async fetchMissions() {
            try {
                const response = await axios.get('https://missions-freelance-industrie.fr/mission/a_valider');
                this.cards = response.data.data;

            } catch (error) {
                console.error('Axios Error: ', error);
            }
        },
        async accept(card) {
            try {
                const response = await axios.post(`https://missions-freelance-industrie.fr/missions/${card._id}/accept`);

                if (response.data && response.data.message) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Accepter',
                        text: response.data.message
                    }).then(() => {
                        location.reload();
                        // this.$router.push('/MissionsValidees');
                    });
                    this.fetchMissions();
                }
            } catch (error) {
                console.error('Error accepting the mission:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'erreur.'
                });
            }
        },
        async notAccept(card) {
            try {
                const response = await axios.post(`https://missions-freelance-industrie.fr/missions/${card._id}/refuse`);

                if (response.data && response.data.message) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Refuser',
                        text: response.data.message
                    }).then(() => {
                        location.reload();
                        // this.$router.push('/dashboard');
                    });
                    this.fetchMissions();
                }
            } catch (error) {
                console.error('Error refusing the mission:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'erreur.'
                });
            }
        },
    },
    async mounted() {
        await this.fetchMissions();
    },
};
</script>

  
<style scoped>
* {
    font-family: Helvetica, sans-serif;
}

.container {
    margin-top: 30px !important;
    margin: 0 auto;
}

.in__container {
    width: 70%;
    margin-top: 25px !important;
    ;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.container .title {
    width: 90%;
    margin: 0 auto;
    text-align: center;
}

.page_title,
.card_title {
    font-weight: bold;
}

.cardavalider {
    padding: 30px 20px;
    border-radius: 10px;
    margin: 15px 0;
}
.card_title {
  margin-bottom:30px;
}
.card_body {
    display: flex;
    align-items: center;
}

.card_description .desc, .comp {
  line-height: 2em;
}

.card_description .desc {

}
.card_description .comp {
  font-weight:bolder;
}
.card_summary {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
}

.card_summary svg {
    font-size: 20px;
}

.card_content {
    width: 95%;
}

.card_actions {
  width: 5%;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 1.5em;
}

.card_actions i {
  font-size: 30px;
}    
.card_actions .details i {
    color: #146c94;}
.card_actions .accept a {
    color: #29AA65;
}

.card_actions .notaccept a {
    color: #C13636;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.box {
    background: #fff;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

.box:hover {
    transform: translateY(-5px);
    box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
    transition: 0.3s;
}


/******/



.accept a {
    color: green;
}

.notaccept a {
    color: red;
}
.btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 10px;
}
.btn {
    padding: 10px 20px;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
}
.btn:hover {
    opacity: 0.9;
    transition: 0.5s;
}
.modif {
    background-color: #146C94;
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 15px;
}
hr{
    margin-top: 20px;
}

@media only screen and (max-width: 600px) {

.in__container {
    margin-top: 5% !important;
    width: 100%;
}
.title {
        margin-top: 15px;
    }

.row {
    margin: 0;
    flex-direction: column;
}

.card_body {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.card_actions {
    width: 100%;
    display: flex;
    margin-top: 20px;
    flex-direction: row;
    justify-content: space-evenly;
}

.card_actions svg {
    font-size: 40px;
}

.card_body .card_summary {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 5px 0;
}

.card_summary a {
    margin: 5px 0;
}
}
</style>
  