<template>
  <Navbar />
  <div class="container">
    <div class="title">
      <h1 class="page_title">Mes missions</h1>
      <br>
      <h2 class="sub_page_title">Details de mission Terminer</h2>
    </div>
    <div class="in__container">
      <div class="form">
        <div class="form-body">
            <div class="cardavalider box">
              <div class="card_body">
                <div class="card_content">
                  <h2 class="card_title">{{ mission.nom_mission }}</h2>
                  <div class="card_summary">
                  <a><i class="fa-regular fa-clock"></i> Publié le : {{ formatMissionDate(mission.created_at) }} </a>
                  <a><i class="fa-solid fa-map-pin"></i> {{ mission.ville }}</a>
                  <a><i class="fa-solid fa-hourglass-start"></i> Démarrage : {{ formatMissionDate(mission.date_debut_ms)
                  }}</a>
                  <a><i class="fa-solid fa-hourglass-end"></i> Fin : {{ formatMissionDate(mission.date_fin) }}</a>
                  <a><i class="fa-regular fa-calendar-days"></i> Durée : {{ convertToMois(mission.duree) }} mois</a>
                </div>
                  <div class="desc">
                    <div class="competences">
                      <h3>Compétences requises</h3>
                      <p>A{{ mission.competences_requises }}</p>
                    </div>
                    <div class="description_mission">
                      <h3>Description</h3>
                      <p>
                        {{ mission.description_mission }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <!-- <div class="sidebar box">
        <h2>Jours de Travail</h2>
        <div class="selected-dates">
          <h3>Dates sélectionnées:</h3>
          <ul class="dates_selected">
            <li v-for="date in consultant.jour_de_travaill" :key="date">{{ formatMissionDate(date) }}</li>
          </ul>
        </div>
      </div> -->
    </div>
  </div>
  <footer></footer>
</template>
  
<script>
import Navbar from '../../components/Pages-admine/Navbar.vue';
import axios from 'axios';
import VueFlatpickr from 'vue-flatpickr-component';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment';


export default {
  name: 'MissionDetails',
  components: {
    Navbar,
  },
  data() {
    return {
      mission: {},
      consultant: {},
      selectedDates: [],
      config: {
        mode: "multiple",
        enableTime: true,
        onChange: (selectedDates) => {
          this.selectedDates = selectedDates;
        }
      }
    };
  },
  methods: {
    convertToMois(days) {
        const months = Math.floor(days / 30);
        const remainingDays = days % 30;
        console.log({months, remainingDays})
        
        if (months > 0) {
            return `${months} mois`;
        } else {
            return `${remainingDays} jours`;
        }
    },
    formatMissionDate(date) {
      return moment(date).format('DD-MM-YYYY');
    },
    async fetchMissionDetail() {
      try {
        const missionId = this.$route.params.id;
        let response = await axios.get(`https://missions-freelance-industrie.fr/missions/consultants/${missionId}`);

        if (response.data) {
          this.mission = response.data.data;
        }
        const consultantId = this.mission.id_consultant;
        response = await axios.get(`https://missions-freelance-industrie.fr/consultants/${consultantId}`);

        if (response.data) {
          this.consultant = response.data.data;
        }
      } catch (error) {
        console.error('Error fetching mission details:', error);
      }
    },
  },
  mounted() {
    this.fetchMissionDetail();
  },
};
</script>
  
<style scoped>
* {
  font-family: Helvetica, sans-serif;
}

.container {
  margin-top: 30px !important;
  margin: 0 auto;
  width: 100%;
}

.in__container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  -moz-column-gap: 2em;
  column-gap: 2em;
  margin-top: 25px;
}

.container .title {
  width: 90%;
  margin: 0 auto;
}

.in__container .form {
  width: 70%;
}

.page_title,
.card_title {
  font-weight: bold;
}

.page_title {
  text-align: center;
  margin-bottom: 15px;
}

.sub_page_title {
  color: #595959;
  font-weight: 600;
}

.sidebar h2 {
  margin-bottom: 30px;
}

.sidebar .dates-from-calendar,
.dates_selected {
  margin-top: 20px;
}

.in__container .sidebar {
  width: 40%;
  margin-top: 15px;
  padding: 30px 15px;
  border: 1px solid #e9e0e0;
  border-radius: 10px;
}

.cardavalider {
  padding: 30px 20px;
  border: 1px solid #e9e0e0;
  border-radius: 10px;
  margin: 15px 0;
}

.card_body {
  display: flex;
  align-items: center;
}

.desc h3 {
  font-weight: bold;
  margin: 15px 0;
}

.desc .competences,
.description_mission {
  margin: 30px 0;
}

.card_summary {
  column-gap: 1em;
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
}
.card_summary a {
  margin-bottom: 10px;
    color: #333;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.5em;
}

.card_summary i {
  font-size: 20px;
  color: #093c89;
}

.card_content {
  width: 100%;
}

.form-body h1 {
  text-align: center;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.cv_card {
  display: flex;
  align-items: center;
  width: 80%;
  height: 70px;
  padding: 10px 5px;
  margin: 20px 10px;
  border: 1px solid #e9e0e0;
  border-radius: 10px;
  box-shadow: 0px 3px 5px -2px rgba(0, 0, 0, 0.5);
}

.box {
  box-shadow: 0px 3px 5px -2px rgba(0, 0, 0, 0.5);
  background: #fff;
}

.dates-from-calendar {
  min-height: 150px;
}



.dates_selected {
  list-style-type: none;
  display: flex;
  max-width: 100%;
  gap: 10px;
  background: #e5f3ff;
  padding: 10px 5px !important;
  border: 1px solid #d4ebfe !important;
  border-radius: 5px;
  font-weight: 700;
  justify-content: space-evenly !important;
}

@media only screen and (max-width: 900px) {
  .title {
        margin-top: 15px;
    }
  .cv_card {
    width: 90%;
  }

  .row {
    margin: 0;
    flex-direction: column;
  }

  .in__container {
    flex-direction: column;
    align-items: center;
  }

  .in__container .form {
    width: 100%;
  }

  .in__container .sidebar {
    width: 100%;
  }

  .card_body {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .card_body .card_summary {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 5px 0;
  }
  .card_summary a {
    margin: 5px 0;
    flex-direction: row;
    align-items: center;
    row-gap: 0.5em;
    column-gap: 0.5em;
  }
}

.flatpickr-calendar {
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange {
  background: #ffffff;
  color: #fff;
}

.flatpickr-day:hover {
  background: #19A7CE;
}

.selected-dates {
  margin-top: 15px;
}

.selected-dates h3 {
  font-size: 16px;
  margin-bottom: 8px;
}

.selected-dates ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  gap: 10px;
}

.selected-dates li {
  /* background-color: #f4f4f4; */
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
}


.flatpickr-calendar .flatpickr-day.selected,
.flatpickr-calendar .flatpickr-day.startRange,
.flatpickr-calendar .flatpickr-day.endRange {
  background: #146C94;
  color: #fff;
}

.flatpickr-calendar .flatpickr-day:hover {
  background: #19A7CE;
}

.flatpickr-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #e9e0e0;
  border-radius: 4px;
  height: auto;
}


</style>

  