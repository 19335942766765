<template>
    <div v-if="missions.length === 0" class="mission_not_added">
      <p>Nous lancerons des annonces trés prochainement.</p>
    </div>
    <div v-else>
      <div v-if="filteredMissions.length === 0" class="mission_not_found">
        <p>Aucune mission ne correspond aux critères de recherche actuels.</p>
      </div>
      <div v-else class="missions_list">
        <div v-for="mission in filteredMissions" :key="mission._id" class="box card">
          <div class="card_body">
            <router-link class="card_body_content" :to="`/detailsannonces/${mission._id}`">
              <div class="card_content">
                <h2 class="card_title">{{ mission.nom_mission }}</h2>
                <div class="card_description" style="margin-bottom:20px;">
                  <p class="desc">{{ mission.description_mission }}</p>
                  <p class="comp">Competences: {{ mission.competences_requises }}</p>
                </div>
                <div class="card_summary">
                  <div class="card_summary_item">
                    <a><i class="fa-regular fa-clock"></i> Publié : {{ formatMissionDate(mission.created_at) }}</a>
                    <a><i class="fa-solid fa-map-pin"></i> {{ mission.ville }}</a>
                  </div>
                  <div class="card_summary_item">
                    <a><i class="fa-solid fa-hourglass-start"></i> Démarage : {{ formatMissionDate(mission.date_debut_ms) }}</a>
                    <a><i class="fa-solid fa-hourglass-end"></i> Durée : {{ convertToMois(mission.duree) }}</a>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
</template>


<script>
import axios from 'axios';
import moment from 'moment-timezone';


export default {
  props: {
    // Receive search criteria as props
    searchType: String,
    searchQuery: String,
  },
  data() {
    return {
      missions: [],
      mission: null,
      searchCompetence: '',
    };
  },
  methods: {
    convertToMois(days) {
        const months = Math.floor(days / 30);
        const remainingDays = days % 30;
        
        if (months > 0) {
            return `${months} mois`;
        } else {
            return `${remainingDays} jours`;
        }
    },
    filterByCompetence() {
      const keyword = this.searchCompetence.toLowerCase();
      this.filteredMissions = this.missions.filter((mission) =>
        mission.competences_requises && mission.competences_requises.toLowerCase().includes(keyword)
      );
    },
    formatMissionDate(date) {
      return moment(date).format('DD-MM-YYYY');
    },
    getMissions() {
      axios.get('https://missions-freelance-industrie.fr/mission/annonces')
        .then(response => {
          this.missions = response.data.data;
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    },
    getMissionById(id) {
      axios.get(`https://missions-freelance-industrie.fr/mission/${id}`)
        .then(response => {
          this.mission = response.data.data;
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    }
  },
  computed: {
    filteredMissions() {
      if (this.searchType === 'all') {
        return this.missions;
      }

      const keyword = this.searchQuery.toLowerCase();
      
      return this.missions.filter((mission) => {
        if (this.searchType === 'nom_mission') {
          return mission.nom_mission && mission.nom_mission.toLowerCase().includes(keyword);
        } 
        if (this.searchType === 'description_mission') {
          return mission.description_mission && mission.description_mission.toLowerCase().includes(keyword);
        } 
        if (this.searchType === 'competences_requises') {
          return mission.competences_requises && mission.competences_requises.toLowerCase().includes(keyword);
        } 
        if (this.searchType === 'ville') {
          return mission.ville && mission.ville.toLowerCase().includes(keyword);
        }
        return true; 
      });
    }
  },
  created() {
    this.getMissions();
  },
}
</script>


<style>
.missions_list{
  display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.mission_not_found, .mission_not_added p{
  text-align: center;
}
.rectangle h4 {
  display: flex;
  width: 80%;
  height: 34px;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
  color: #146C94;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/*
.rectangle p {
  display: flex;
  width: 405px;
  height: 29px;
  flex-direction: column;
  justify-content: center;
  color: #000;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-left: 20px;
}

.rectangle span{
  display: flex;
  margin-top: 20px;
  width: 90px;
  height: 15px;
  flex-direction: column;
  justify-content: center;
  color: #000;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  margin-left: 20px;
  line-height: normal;
}*/
.spans {
  display: flex;
  justify-content: space-between;
}

.spans span {
  width: 70px;
  color: #000;
  font-family: Inter;
  font-size: 10px;
  font-weight: 100;
  margin-left: 20px;
  line-height: normal;
  margin-top: 20px;
}

.router-link-active,
.router-link-active a {
  text-decoration: none;
  color: #000;
}

.router-link-active * {
  text-decoration: none;
}

a,
a.router-link-active {
  text-decoration: none !important;
}

a * {
  text-decoration: none !important;
}

.card {
  padding: 25px 30px;
  border-radius: 10px;
  margin: 15px 0;
  width: 70%;
  background: #fff;
}

.card_title {
  margin-bottom: 30px;
}

/*********** */
.card_title {
  font-weight: bold;
  color: #000;
}

.card_description {
  color: #000;
}

.card_description .desc,
.comp {
  line-height: 2em;
}

/* .card_description .desc {} */

.card_description .comp {
  font-weight: bolder;
}

.card_body {
  display: flex;
  align-items: center;
}

.card_body_content {
  width: 100%;
  display: flex;
}

.card_summary {
  display: flex;
    padding: 5px 0;
    color: #333;
    column-gap: 5em;
}
.card_summary .card_summary_item {
  width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }


.card_summary i {
  font-size: 20px;
  color: #19a7ce;
}

.card_summary a {
  margin-bottom: 10px; /* Add space between items */
  text-decoration: none;
  color: #333;
  font-size: 14px;
}

.card_content {
  width: 90%;
}

.card_price {
  width: 10%;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  align-content: flex-end;
  flex-direction: column-reverse;
}

.card_price .price {
  color: #2c2e31;
  font-size: 20px;
  font-weight: bold;
}

.box {
  box-shadow: 0px 2px 7px 0px #aaaaaa;
}

.box:hover {
  transform: translateY(-5px);
  box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
  transition: 0.3s;
}

/*********** */

@media (max-width: 768px) {
  .cont {
    width: 100% !important;
  }
  .search {
    width: 90% !important;
  }
  .card {
    width: 90%;
    padding: 15px 20px;
  }

  .card_title {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .card_description .desc,
  .comp {
    font-size: 14px;
  }

  .card_summary {
    flex-direction: column;
  }

  .card_summary .card_summary_item {
    width: 100%; 
    margin-right: 0; 
    margin-bottom: 10px; 
  }

  .card_summary i {
    font-size: 16px;
  }
}
</style>
