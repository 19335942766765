<template>
  <div class="container">
    <div class="form-postuler">
      <h1>Postuler votre candidature</h1>
      <form @submit.prevent="handleSubmit">
        <div class="form-body">
          <div class="row">
            <div class="input-group" :class="{ error: nomError }">
              <label for="nom">Nom</label>
              <input type="text" id="nom" placeholder="Entrez votre Nom" v-model="nom">
              <span class="error-message" v-if="nomError">{{ nomError }}</span>
            </div>
            <div class="input-group" :class="{ error: prenomError }">
              <label for="prenom">Prénom</label>
              <input type="text" id="prenom" placeholder="Entrez votre Prénom" v-model="prenom">
              <span class="error-message" v-if="prenomError">{{ prenomError }}</span>
            </div>
          </div>

          <div class="row">
            <div class="input-group" :class="{ error: emailError }">
              <label for="email">Email</label>
              <input type="email" id="email" placeholder="name@domaine.com" v-model="email">
              <span class="error-message" v-if="emailError">{{ emailError }}</span>
            </div>
            <div class="input-group" :class="{ error: telephoneError }">
              <label for="telephone">Numéro de Téléphone</label>
              <input type="tel" id="telephone" placeholder="+33 6 00 00 00 00" v-model="telephone">
              <span class="error-message" v-if="telephoneError">{{ telephoneError }}</span>
            </div>
          </div>

          <div class="row">
            <div class="input-group" :class="{ error: diponibiliteError }">
              <label for="nom">Disponibilté</label>
              <input type="date" id="disponibilite" placeholder="01/01/2023" v-model="disponibilite">
              <span class="error-message" v-if="diponibiliteError">{{ diponibiliteError }}</span>
            </div>
            <div class="input-group" :class="{ error: tjmError }">
              <label for="prenom">Taux journalier moyen</label>
              <input type="number" id="tjm" placeholder="00" v-model="tjm">
              <span class="error-message" v-if="tjmError">{{ tjmError }}</span>
            </div>
          </div>
          <div class="row">
            <div class="input-group" :class="{ error: naissanceError }">
              <label for="nom">Date de Naissance</label>
              <input type="date" id="naissance" placeholder="01/01/2023" v-model="naissance">
              <span class="error-message" v-if="naissanceError">{{ naissanceError }}</span>
            </div>
            <div class="input-group">

            </div>
          </div>

          <div class="row">
            <div class="input-group" :class="{ error: fileError }">
              <label for="cv">Télécharger le CV</label>
              <div class="file-drop-area">
                <span class="fake-btn">Choisir le CV</span>
                <span class="file-msg">ou faites glisser et déposez le fichier ici</span>
                <input class="file-input" type="file" multiple @change="handleFileUpload">
                <span class="file-name-container">{{ fileName }}</span>
              </div>
              <span class="error-message" v-if="fileError">{{ fileError }}</span>
            </div>
          </div>
          <div class="row">
            <div class="input-group">
              <input type="submit" value="Postuler">
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      nom: '',
      prenom: '',
      email: '',
      telephone: '',
      file: null,
      disponibilite: '',
      naissance: '',
      tjm: '',
      fileName: '',
      id_consultant: '64c11f6ac3921712c401041f',
      id_mission: '',
      consultant_status: false,
    };
  },
  methods: {
    handleSubmit() {
      let isValid = true;
      // Validate nom
      if (!this.nom.trim()) {
        this.nomError = 'Le nom est requis.';
        isValid = false;
      } else {
        this.nomError = '';
      }

      // Validate prenom
      if (!this.prenom.trim()) {
        this.prenomError = 'Le prénom est requis.';
        isValid = false;
      } else {
        this.prenomError = '';
      }

      // Validate email
      if (!this.email.trim()) {
        this.emailError = 'L\'email est requis.';
        isValid = false;
      } else if (!this.validateEmail(this.email)) {
        this.emailError = 'Veuillez entrer une adresse email valide.';
        isValid = false;
      } else {
        this.emailError = '';
      }

      // Validate telephone
      if (!this.telephone.trim()) {
        this.telephoneError = 'Le numéro de téléphone est requis.';
        isValid = false;
      } else if (!this.validatePhoneNumber(this.telephone)) {
        this.telephoneError = 'Veuillez entrer un numéro de téléphone français valide.';
        isValid = false;
      } else {
        this.telephoneError = '';
      }

      // Validate disponibilite (date)
      if (!this.disponibilite) {
        this.disponibiliteError = 'La disponibilité est requise.';
        isValid = false;
      } else {
        this.disponibiliteError = '';
      }

      // Validate tjm (number)
      if (!this.tjm) {
        this.tjmError = 'Le Taux journalier moyen est requis.';
        isValid = false;
      } else {
        this.tjmError = '';
      }

      // Validate datenaissance (date)
      if (!this.validateNaissance(this.naissance)) {
      this.naissanceError = 'La date de naissance est requise.';
      isValid = false;
    } else {
      const birthDate = new Date(this.naissance);
      const today = new Date();
      const age = today.getFullYear() - birthDate.getFullYear();

      if (age < 18) {
        this.naissanceError = 'Vous devez avoir au moins 18 ans pour postuler.';
        isValid = false;
      } else {
        this.naissanceError = ''; // No error message
      }
    }


      // Validate file
      if (!this.file) {
        this.fileError = 'Veuillez sélectionner un fichier PDF.';
        isValid = false;
      } else {
        this.fileError = '';
      }

      if (!isValid) {
        this.showErrorMessage = true;
        setTimeout(() => {
          this.showErrorMessage = false;
        }, 5000);
        return;
      }
      let formData = new FormData();
      formData.append('nom_candidat', this.nom);
      formData.append('prenom_candidat', this.prenom);
      formData.append('email_candidat', this.email);
      formData.append('tel_candidat', this.telephone);
      formData.append('disponibilte', this.disponibilite);
      formData.append('TJM', this.tjm);
      formData.append('date_naissance', this.naissance);
      formData.append('cv_candidat', this.file);
      formData.append('id_consultant', this.id_consultant);
      formData.append('consultant_status', this.consultant_status);
      const id_mission = this.$route.params.id;
      formData.append('id_mission', id_mission);
      axios
        .post('https://missions-freelance-industrie.fr/candidature/add', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          this.nom = '';
          this.prenom = '';
          this.email = '';
          this.telephone = '';
          this.disponibilite = '';
          this.tjm = '';
          this.naissance = '';
          this.file = null;
          this.fileName = '';
          window.location.href = '/';
        })
        .catch(error => {
          if (error.response && error.response.status === 400) {
            this.showErrorAlert(error.response.data.error);
          } else {
            this.showErrorAlert('Une erreur s\'est produite. Veuillez réessayer.');
          }
        });
    },
    handleFileUpload(event) {
      // this.file = event.target.files[0];
      // this.fileName = this.file.name;
      const selectedFile = event.target.files[0];

      if (selectedFile && selectedFile.type === 'application/pdf') {
        this.file = selectedFile;
        this.fileName = selectedFile.name;
      } else {
        alert("Veuillez sélectionner un fichier PDF.");
        this.file = null;
        this.fileName = '';
      }
    },
    validateNom(nom) {
      return nom.trim() !== '';
    },

    validatePrenom(prenom) {
      return prenom.trim() !== '';
    },

    validateDisponibilte(disponibilite) {
      return disponibilite.trim() !== '';
    },

    validateTjm(tjm) {
      return tjm !== '';
    },

    validateNaissance(naissance) {
      return naissance.trim() !== '';
    },

    validateEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },

    validatePhoneNumber(phoneNumber) {
      const phoneRegex = /^(?:(?:(?:\+|00)33\s?|0)[1-9](?:\s?\d{2}){4})$/;
      return phoneRegex.test(phoneNumber);
    },
    handleError(error) {
      if (error.response && error.response.data && error.response.data.message) {
        if (error.response.data.message.includes("email already exists")) {
          this.showErrorAlert('L\'email existe déjà.');
        } else {
          this.showErrorAlert('Une erreur s\'est produite. Veuillez réessayer.');
        }
      } else {
        this.showErrorAlert('Une erreur s\'est produite. Veuillez réessayer.');
      }
    },
    showErrorAlert(message) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: message,
      });
    }
  },
  computed: {
    nomError() {
      if (!this.validateNom(this.nom)) {
        return 'Le nom est requis.';
      }
      return '';
    },

    prenomError() {
      if (!this.validatePrenom(this.prenom)) {
        return 'Le prénom est requis.';
      }
      return '';
    },

    emailError() {
      if (this.email === '') {
        return 'L\'email est requis.';
      } else if (!this.validateEmail(this.email)) {
        return 'Veuillez entrer une adresse email valide.';
      }
      return '';
    },

    telephoneError() {
      if (this.telephone === '') {
        return 'Le numéro de téléphone est requis.';
      } else if (!this.validatePhoneNumber(this.telephone)) {
        return 'Veuillez entrer un numéro de téléphone français valide.';
      }
      return '';
    },

    diponibiliteError() {
      if (this.disponibilite === '') {
        return 'La date est requis.';
      } else if (!this.validateDisponibilte(this.disponibilite)) {
        return 'Veuillez entrer une date valide.';
      }
      return '';
    },

    naissanceError() {
    if (!this.validateNaissance(this.naissance)) {
      return 'La date de naissance est requise.';
    } else {
      const birthDate = new Date(this.naissance);
      const today = new Date();
      const age = today.getFullYear() - birthDate.getFullYear();

      if (age < 18) {
        return 'Vous devez avoir au moins 18 ans pour postuler.';
      }

      return ''; // No error message
    }
  },


    tjmError() {
      if (this.tjm === '') {
        return 'Le taux journailer moyen est requis.';
      } else if (!this.validateTjm(this.tjm)) {
        return 'Veuillez entrer une valeu valide.';
      }
      return '';
    },
    fileError() {
      if (!this.file) {
        return 'Veuillez sélectionner un fichier PDF.';
      }
      return '';
    },
  }
}
</script>

  
<style scoped>
.container {
  width: 100%;
  margin: 6% auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  justify-content: space-around;
}

.form-postuler {
  width: 50%;
  border-radius: 15px;
  border: 1px solid #BFBFBF;
  background-color: white;
  box-shadow: 0px 2px 10px 0px #aaaaaa;
  padding: 30px 20px;
}

.form-postuler h1 {
  text-align: center;
}

.row {
  margin: 30px 0;
  display: flex;
  flex-direction: row;
}

label {
  padding: 10px 0;
}

.input-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 10px 20px;
}

.input-group label {
  color: #222;
  font-size: 17px;
  font-weight: 500;
}

.input-group input[type="text"],
.input-group input[type="email"],
.input-group input[type="tel"],
.input-group input[type="date"],
.input-group input[type="number"] {
  font-size: 14px;
  height: 34px;
  padding: 5px 10px;
  color: #555;
  border: none;
  border-bottom: 2px solid #BFBFBF;
  background: white;
  outline: none;
}

.input-group input[type="text"]:focus,
.input-group input[type="email"]:focus,
.input-group input[type="tel"]:focus,
.input-group input[type="date"]:focus,
.input-group input[type="number"]:focus {
  border-bottom: 2px solid #000000;
  transition: 0.8s;
}

.input-group input[type="submit"] {
  font-size: 20px;
  padding: 10px 10px;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  background-color: #19A7CE;
  outline: none;
}

.input-group input[type="submit"]:hover {
  background-color: #146C94;
  color: #ffffff;
  transition: 0.3s;
}

.file-drop-area {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 100%;
  padding: 25px;
  border: 2px dashed #bfbfbf;
  border-radius: 3px;
  transition: 0.2s;
}

.file-drop-area.is-active {
  background-color: rgba(255, 255, 255, 0.05);
}

.fake-btn {
  flex-shrink: 0;
  background-color: #333;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  padding: 8px 15px;
  margin-right: 10px;
  font-size: 12px;
  text-transform: uppercase;
  color: #ffffff;
}

.file-msg {
  font-size: small;
  font-weight: 400;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #595959;
}

.file-input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;

  &:focus {
    outline: none;
  }
}

@media only screen and (max-width: 550px) {
  .row {
    margin: 0;
    flex-direction: column;
  }

  .form-postuler {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .form-postuler {
    width: 90%;
  }
}

.error-message {
  color: #fff;
  background: #ff000082;
  width: 100%;
  padding: 5px;
  margin-top: 5px;
  border-radius: 8px;
  border: 1px solid #ff0000ad;
}

.input-group .error {
  border-color: red;
}
</style>
